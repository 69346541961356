<template>
    <div>
        <el-drawer
            title="导入工作人员"
            :visible.sync="drawerShow"
            :wrapperClosable="false"
            :destroy-on-close="true"
            size="auto"
            @close="close"
        >
            <div class="drawer-content">
                <div class="temp-head clearfix">
                    <span class="fl">导入示例</span>
                    <el-button class="fr" icon="el-icon-download" size="mini" type="text" :loading="downLoading" @click="download">下载模板</el-button>
                </div>
                <div class="temp-table">
                    <el-table
                        :data="tempModel"
                        style="width: 100%"
                        size="small"
                        header-row-class-name="page-list-table"
                    >
                        <el-table-column prop="n1" label="工作证编号"></el-table-column>
                        <el-table-column prop="n2" label="证件姓名"></el-table-column>
                        <el-table-column prop="n3" label="证件号码" min-width="150"></el-table-column>
                        <el-table-column prop="n4" label="联系手机" min-width="100"></el-table-column>
                    </el-table>
                </div>
                <div class="temp-tips MT20">
                    <div><span class="el-icon-info color-warning"></span> 导入提示</div>
                    <div class="color-regular">
                        <p>该模板仅用于导入演出工作人员信息</p>
                        <p>1、工作证编号：<span class="color-danger">必填</span>，仅支持整数数字格式</p>
                        <p>2、证件姓名：<span class="color-danger">选填</span>，文本格式，任意填写</p>
                        <p>3、证件号码：<span class="color-danger">选填</span>，比如身份证号码</p>
                        <p>4、联系手机：<span class="color-danger">选填</span>，无须填写国际区号</p>
                    </div>
                </div>
                <el-row class="MT20" type="flex" align="middle">
                    <el-col :span="3">场次：</el-col>
                    <el-col :span="21">
                        <el-select v-model="sessionId" size="small" style="width:100%">
                            <el-option v-for="(item,index) in sessionIdNameList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="MT20" type="flex" align="middle">
                    <el-col :span="3">文件：</el-col>
                    <el-col :span="21">
                        <el-upload
                            action
                            :http-request="uploadExcel"
                            :auto-upload="false"
                            ref="upload"
                            accept=".xls, .xlsx"
                            :show-file-list="true"
                            :before-upload="beforeUpload"
                            :file-list="fileList"
                            :on-change="onChange"
                            :on-remove="onRemove"
                            :disabled="uploadLoading"
                            style="width:100%">
                            <el-button size="small" style="width:100%" type="primary" :loading="uploadLoading">{{uploadLoading ? '上传中' : '选择文件'}}</el-button>
                        </el-upload>
                    </el-col>
                </el-row>
                <div class="MT20">
                    <p>只能上传xls/xlsx文件，且最多不超过10W行</p>
                    <p class="MT10">对于具有多个场次的演出，请仔细选择上传的场次，每个场次都需要单独上传工作人员数据。</p>
                    <p class="MT10 color-danger">请注意：每次导入都是增量导入（不会覆盖之前导入的数据），如需修改之前导入的数据，请联系技术。</p>
                </div>
                <div class="MT20">
                    <p>导入以后，可以使用PDA或闸机核实工作人员身份。</p>
                </div>
            </div>
            <div class="drawer-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="canSubmit"
                    >提交</el-button>
            </div>

             <ErrorMsgDrawer ref="errorMsgDrawer"></ErrorMsgDrawer>    

        </el-drawer>
    </div>
</template>

<script>
import {downloadFileTmp,uploadFileTmp} from '@/assets/js/commonApi.js';
import ErrorMsgDrawer from '@/components/ErrorMsgDrawer';
export default {
    props: {
        id: {
            type: [Number, String],
            default: '',
        },
        sessionIdNameList: {
            type: Array,
            default: () => []
        }
    },
    components:{ErrorMsgDrawer},
    data(){
        return{
            drawerShow:false,
            btnLoading:false,
            downLoading:false,
            uploadLoading:false,
            tempModel:[{
                n1:'1100022010101',
                n2:'张三',
                n3:'510108888888888881',
                n4:'18888888888',
            },{
                n1:'1100022010102',
                n2:'李四',
                n3:'510108888888888882',
                n4:'18888888881',
            }],
            canSubmit:true,
            fileList:[],
            // errMsgShow:false,
            // errMsgList:[]
            sessionId: "",
        }
    },
    methods:{
        show() {
            this.drawerShow = true;
        },
        close(){
            this.fileList = [];
            this.$refs.upload.clearFiles();
            this.drawerShow = false;
        },
        //下载模板
        async download(){
            let filePath;
            this.downLoading = true;
            const res = await this.$request({
                url:'/activitySaleStatistice/staffIc/downloadTemp',
                method: 'post',
            })
                .catch(err => {
                    this.$message({message: err.message, type: 'error'});
                })
            const{state,msg,result:{url}} = res.data;
            if(state == 1 &&  url){
                filePath = url;
            }else{
                this.downLoading = false;
                return this.$message.error(msg || '下载失败');
            }   

            await downloadFileTmp(filePath,'演出工作人员.'+this.$utils.getFileType(filePath));
            this.downLoading = false;
        },
        onSubmit(){
            if( !this.sessionId ){
                return this.$message.error('请选择场次');
            }
            this.$refs.upload.submit();
        },
        async uploadExcel(req){
            this.btnLoading = true;
            this.uploadLoading = true;
            const formData = new FormData();
            formData.append('file', req.file);

            let filePath;
            const res = await uploadFileTmp(formData).catch(err => {
                this.$message({message: err.message, type: 'error'});
            }).finally(() => {
                this.uploadLoading = false;
            });
            const{state,msg,result} = res.data;
            if (state == 1 && result) {
                filePath = result.url;
            }else{
                this.$message.error(msg || '上传失败');
            }

            this.$request({
                url:'/activitySaleStatistice/staffIc/import',
                method: 'post',
                params:{path:filePath,activityId:this.id,sessionId: this.sessionId}
            })
                .then(res => {
                    const{state,msg,result} = res.data;
                    if (state == 1) {
                        if(result && result.importErrorDetailVOList){
                            this.$refs.errorMsgDrawer.show(result);
                        }else{
                            this.onSuccess();
                        }
                        
                    }else{
                        this.$message.error(msg || '上传失败');
                        this.canSubmit = false;
                    }
                })
                .catch(err => {
                    this.canSubmit = false;
                    this.$message({message: err.message, type: 'error'});
                })
                .finally(() => {
                    this.btnLoading = false;
                    this.canSubmit = true;
                });
        },
        beforeUpload(file){
            const type = this.$utils.getFileType(file.name)
            const isType = type === 'xls' || type === 'xlsx';
            if (!isType) {
                this.$message.error('仅支持xls、xlsx文件格式,请重新选择上传文件!');
            }
            return isType;
        },
        onSuccess(){
            this.$emit('onUpdate');
            this.close();
        },
        onChange(file){
            if( file ){
                this.fileList = [{...file}]
                this.canSubmit = false;
            }else{
                this.canSubmit = true;
            }
        },
        onRemove(){
            this.canSubmit = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.temp-head{
    line-height: 28px;
}
.temp-table{
    margin-top: 15px;
}
.temp-tips{
    border-bottom: 1px #EBEEF5 solid;
    padding-bottom: 15px;
    .el-icon-info{
        font-size: 16px;
    }
    .color-regular{
        margin-top: 8px;
        font-size: 13px;
        line-height: 20px;
    }
}
</style>