<template>
    <div>
        <el-drawer
            title="导入自有渠道非实名制电子票"
            :visible.sync="drawerShow"
            :wrapperClosable="false"
            :destroy-on-close="true"
            size="600px"
            @close="close"
        >
            <div class="drawer-content">
                <div class="temp-head clearfix">
                    <span class="fl">导入示例</span>
                    <el-button class="fr" icon="el-icon-download" size="mini" type="text" :loading="downLoading" @click="download">下载模板</el-button>
                </div>
                <div class="temp-table">
                    <el-table
                        :data="tempModel"
                        style="width: 100%"
                        size="small"
                        header-row-class-name="page-list-table"
                    >
                        <el-table-column prop="n1" label="渠道名称"></el-table-column>
                        <!-- <el-table-column prop="n2" label="场次"></el-table-column> -->
                        <el-table-column prop="n2" label="票品" width="120"></el-table-column>
                        <el-table-column prop="n3" label="单价" width="80"></el-table-column>
						<el-table-column prop="n6" label="数量(非选座演出)"></el-table-column>						
						<el-table-column prop="n4" label="票品座位号(选座演出)" v-if="isSeat == 1"></el-table-column>                        
                        <el-table-column prop="n5" label="用户手机" min-width="100"></el-table-column>
                    </el-table>
                </div>
                <div class="temp-tips MT20">
                    <div><span class="el-icon-info color-warning"></span> 导入提示</div>
                    <div class="color-regular">
                        <p>该模板仅用于导入<span class="color-danger">非</span>秀动销售的<span class="color-danger">非实名制电子票</span>，以便使用秀动专业版APP统一验票</p>
						<p v-if="isSeat==1"><span class="color-danger">本场演出为选座项目，导入数据必须包含座位号，且每个座位号一行。</span></p>
                        <p>1、渠道名称：<span class="color-danger">必填</span>，文本格式，任意填写，比如：淘宝、大麦</p>                       
                        <p>2、票品：<span class="color-danger">必填</span>，文本格式，<span class="color-danger">必须与秀动系统中的票品名称一致</span>,如：预售票、现场票等，会自动继承票品的【核销后再次出入场】设置</p>
                        <p>3、单价：<span class="color-danger">必填</span>，小数格式，精确到小数点后2位，<span class="color-danger">如果不清楚价格或者属于免费票，请填写【0.00】</span></p>
						<p>4、数量：<span class="color-danger">非选座演出，必填</span>，数字格式，票品的购买数量</p>						
                        <p>5、用户手机：<span class="color-danger">必填</span>，数字格式，无须填写国际区号</p>
						<p v-if="isSeat==1">6、票品座位号: <span class="color-danger">选座演出必填</span>, 格式为xx层xx区xx排xx号, 比如 一层F区13排18号</p>
                    </div>
                </div>
                <el-row class="MT20" type="flex" align="middle">
                    <el-col :span="3">场次：</el-col>
                    <el-col :span="21">
                        <el-select v-model="sessionId" size="small" style="width:100%">
                            <el-option v-for="(item,index) in sessionIdNameList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="MT20" type="flex" align="middle">
                    <el-col :span="3">文件：</el-col>
                    <el-col :span="21">
                        <el-upload
                            action
                            :http-request="uploadExcel"
                            :auto-upload="false"
                            ref="upload"
                            accept=".xls, .xlsx"
                            :show-file-list="true"
                            :before-upload="beforeUpload"
                            :file-list="fileList"
                            :on-change="onChange"
                            :on-remove="onRemove"
                            :disabled="uploadLoading"
                            style="width:100%">
                            <el-button size="small" style="width:100%" type="primary" :loading="uploadLoading">{{uploadLoading ? '上传中' : '选择文件'}}</el-button>
                        </el-upload>
                    </el-col>
                </el-row>
                <div class="MT20">
                    <p>只能上传xls/xlsx文件，且最多不超过10W行</p>
                    <p class="MT10">对于具有多个场次的演出，请仔细选择上传的场次，每个场次都需要单独上传售票数据。<br>如果需要上传通票，则要求选择通票绑定的任一场次，之后无须再到其他场次上传，就能自动成为通票。</p>
                    <p class="MT10 color-danger">请注意：每次导入都是增量导入（不会覆盖之前导入的数据），如需修改之前导入的数据，请联系技术。</p>
                </div>
                <div class="MT20">
                    <p>导入以后，可以使用PDA或闸机统一验票。</p>
                    <p class="MT10">下载详单也可以看到全部的售票数据（包括秀动和自有渠道的）。</p>
                </div>
            </div>
            <div class="drawer-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="canSubmit"
                    >提交</el-button>
            </div>

             <ErrorMsgDrawer ref="errorMsgDrawer"></ErrorMsgDrawer>    

        </el-drawer>
    </div>
</template>

<script>
import {downloadFileTmp,uploadFileTmp} from '@/assets/js/commonApi.js';
import ErrorMsgDrawer from '@/components/ErrorMsgDrawer';
export default {
    props: {
        id: {
            type: [Number, String],
            default: '',
        },
		isSeat: {
		    type: [Number, String],
		    default: '',
		},
        sessionIdNameList: {
            type: Array,
            default: () => []
        }
    },
    components:{ErrorMsgDrawer},
    data(){
        return{
            drawerShow:false,
            btnLoading:false,
            downLoading:false,
            uploadLoading:false,
            tempModel:[{
                n1:'大麦',
                n2:'预售票',
                n3:'100.00',               
                n4:'一层F区13排18号',
                n5:'18888888888',
				n6:'100'
            },{
                n1:'大麦',
                n2:'全价票',
                n3:'100.00',               
                n4:'一层F区13排18号',
                n5:'19999999999',
				n6:'100'
            }],
            canSubmit:true,
            fileList:[],
            // errMsgShow:false,
            // errMsgList:[]
            sessionId: "",
        }
    },
    methods:{
        show() {
            this.drawerShow = true;
        },
        close(){
            this.fileList = [];
            this.$refs.upload.clearFiles();
            this.drawerShow = false;
        },
        //下载模板
        async download(){
            let filePath;
            this.downLoading = true;
            const res = await this.$request({
                url:'/activitySaleStatistice/downloadTemp',
				params: { activityId: parseInt(this.id) },
                method: 'post',
            })
                .catch(err => {
                    this.$message({message: err.message, type: 'error'});
                })
            const{state,msg,result:{url}} = res.data;
            if(state == 1 &&  url){
                filePath = url;
            }else{
                this.downLoading = false;
                return this.$message.error(msg || '下载失败');
            }   

            await downloadFileTmp(filePath,'自有渠道非实名制电子票.'+this.$utils.getFileType(filePath));
            this.downLoading = false;
        },
        onSubmit(){
            if( !this.sessionId ){
                return this.$message.error('请选择场次');
            }
            this.$refs.upload.submit();
        },
        async uploadExcel(req){
            this.btnLoading = true;
            this.uploadLoading = true;
            const formData = new FormData();
            formData.append('file', req.file);

            let filePath;
            const res = await uploadFileTmp(formData).catch(err => {
                this.$message({message: err.message, type: 'error'});
            }).finally(() => {
                this.uploadLoading = false;
            });
            const{state,msg,result} = res.data;
            if (state == 1 && result) {
                filePath = result.url;
            }else{
                this.$message.error(msg || '上传失败');
            }

            this.$request({
                url:'/activitySaleStatistice/import',
                method: 'post',
                params:{path:filePath,activityId:this.id,sessionId: this.sessionId}
            })
                .then(res => {
                    const{state,msg,result} = res.data;
                    if (state == 1) {
                        if(result && result.importErrorDetailVOList){
                            this.$refs.errorMsgDrawer.show(result);
                        }else{
                            this.onSuccess();
                        }
                        
                    }else{
                        this.$message.error(msg || '上传失败');
                        this.canSubmit = false;
                    }
                })
                .catch(err => {
                    this.canSubmit = false;
                    this.$message({message: err.message, type: 'error'});
                })
                .finally(() => {
                    this.btnLoading = false;
                    this.canSubmit = true;
                });
        },
        beforeUpload(file){
            const type = this.$utils.getFileType(file.name)
            const isType = type === 'xls' || type === 'xlsx';
            if (!isType) {
                this.$message.error('仅支持xls、xlsx文件格式,请重新选择上传文件!');
            }
            return isType;
        },
        onSuccess(){
            this.$emit('onUpdate');
            this.close();
        },
        onChange(file){
            if( file ){
                this.fileList = [{...file}]
                this.canSubmit = false;
            }else{
                this.canSubmit = true;
            }
        },
        onRemove(){
            this.canSubmit = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.temp-head{
    line-height: 28px;
}
.temp-table{
    margin-top: 15px;
}
.temp-tips{
    border-bottom: 1px #EBEEF5 solid;
    padding-bottom: 15px;
    .el-icon-info{
        font-size: 16px;
    }
    .color-regular{
        margin-top: 8px;
        font-size: 13px;
        line-height: 20px;
    }
}
</style>