<template>
	<div>
		<div class="page-top-box">
			<div class="page-title-box">
				<div class="go-back">
					<el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
				</div>
				{{ $route.meta.title }}
			</div>
		</div>		
		<!-- <activity-tab name="2"></activity-tab> -->
		<div class="ML20"><tab name="1"></tab></div>
		<div v-loading="dataLoading">			
			<div>
				<small v-if="id != 197710" class="color-danger"><span class="el-icon-warning color-warning"></span>
					仅包括秀动平台为您带来的销售数据，赠票以及您自有渠道的销售数据请看后面的板块</small>
			</div>
			<div class="page-group-box MT20">
				<div class="page-group-title">{{ data && data.activityTitle  }} <el-link :href="`/manage/activity/edit/statisticschart/${id}`" target="_blank" class="ML10"> (查看分时销售数据)
					</el-link></div>
				<el-row class="info-box">
					<el-col :xl="16">
						<el-row :gutter="40">
							<el-col :sm="8">
								<label>销售收入</label>
								<span class="color-regular">{{data && data.totalAmount || 0.00}}</span>
							</el-col>
							<el-col :sm="8">
								<label>购票人数</label>
								<span v-if="id == 197710" class="color-regular">{{data && data.totalOrder || 0}}</span>
								<span v-else class="color-regular">{{data && data.totalBuyer || 0}}</span>
							</el-col>
							<el-col :sm="8">
								<label>订单总数</label>
								<span class="color-regular">{{data && data.totalOrder || 0}}</span>
							</el-col>
							<el-col :sm="8">
								<label>售票总数</label>
								<span class="color-regular">{{data && data.totalSaleTicket || 0}}</span>
							</el-col>
							<el-col :sm="8">
								<label>退票总数</label>
								<span class="color-regular">{{data && data.totalRefundNum || 0}}</span>
							</el-col>
							<el-col :sm="8">
								<label>退票手续费</label>
								<span class="color-regular">{{data && data.totalRefundFeeStr || 0}}</span>
							</el-col>
						</el-row>
					</el-col>
				</el-row>

			</div>
			<div class="page-group-box MT20">
				<div class="page-group-title">场次<el-link :href="`/manage/activity/edit/statisticschart/${id}`" target="_blank" class="ML10"> (查看分时销售数据)
					</el-link></div>
				<div class="MT20" v-if="data && data.normalTicketInfo && data.normalTicketInfo.length > 0">
					<el-tabs v-model="sessionName" type="card">
						<el-tab-pane v-for="(item, index) in data.normalTicketInfo" :key="index"
							:label="item.sessionName" :name="index.toString()">
							<el-row class="info-box clearfix">
								<el-col :xl="16">
									<el-row :gutter="40">
										<el-col :sm="8">
											<label>销售收入</label>
											<span
												class="color-regular">{{data && data.normalTicketInfo[index].totalAmount || 0.00}}</span>
										</el-col>
										<el-col :sm="8">
											<label>购票人数</label>
											<span v-if="id == 197710"
												class="color-regular">{{data && data.normalTicketInfo[index].totalOrder || 0}}</span>
											<span v-else
												class="color-regular">{{data && data.normalTicketInfo[index].totalBuyer || 0}}</span>
										</el-col>
										<el-col :sm="8">
											<label>订单总数</label>
											<span
												class="color-regular">{{data && data.normalTicketInfo[index].totalOrder || 0}}</span>
										</el-col>
										<el-col :sm="8">
											<label>售票总数</label>
											<span
												class="color-regular">{{data && data.normalTicketInfo[index].totalSaleTicket || 0}}</span>
										</el-col>
										<el-col :sm="8">
											<label>退票总数</label>
											<span
												class="color-regular">{{data && data.normalTicketInfo[index].refundNum || 0}}</span>
										</el-col>
										<el-col :sm="8">
											<label>退票手续费</label>
											<span
												class="color-regular">{{data && data.normalTicketInfo[index].refundFeeStr || 0.00}}</span>
										</el-col>
									</el-row>
								</el-col>
							</el-row>
							<div v-if="data && data.normalTicketInfo[index]" class="sessions-box MT20">
								<el-table :data="data.normalTicketInfo[index].ticketDetails" style="width: 100%"
									size="small" header-row-class-name="page-list-table">
									<el-table-column prop="sessionName" label="场次"></el-table-column>
									<el-table-column prop="ticketName" label="票品"></el-table-column>
									<el-table-column label="售票总数">
										<template slot-scope="scope">
										    <p>售票数：{{scope.row.totalSaleTicket}}</p>
										    <p>总库存：{{scope.row.totalStock}}</p>
										</template>
									</el-table-column>
									<el-table-column prop="totalAmount" label="销售收入"></el-table-column>
									<el-table-column prop="sessionIncome" label="收入场次占比"></el-table-column>
									<el-table-column prop="activityIncome" label="收入演出占比"></el-table-column>									
									<el-table-column prop="sessionTicket" label="售票数场次占比"></el-table-column>
									<el-table-column prop="activityTicket" label="售票数演出占比"></el-table-column>
									<el-table-column prop="refundNum" label="退票总数"></el-table-column>
									<el-table-column prop="refundFeeStr" label="退票手续费"></el-table-column>
								</el-table>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<div class="page-group-box MT20" v-if="data && data.throughTicketInfo">
				<div class="page-group-title">通票 <el-link :href="`/manage/activity/edit/statisticschart/${id}`" target="_blank" class="ML10"> (查看分时销售数据)
					</el-link></div>
				<el-row class="info-box">
					<el-col :xl="16">
						<el-row :gutter="40">
							<el-col :sm="8">
								<label>销售收入</label>
								<span
									class="color-regular">{{data && data.throughTicketInfo.totalAmount || 0.00}}</span>
							</el-col>
							<el-col :sm="8">
								<label>购票人数</label>
								<span v-if="id == 197710"
									class="color-regular">{{data && data.throughTicketInfo.totalOrder || 0}}</span>
								<span v-else
									class="color-regular">{{data && data.throughTicketInfo.totalBuyer || 0}}</span>
							</el-col>
							<el-col :sm="8">
								<label>订单总数</label>
								<span class="color-regular">{{data && data.throughTicketInfo.totalOrder || 0}}</span>
							</el-col>
							<el-col :sm="8">
								<label>售票总数</label>
								<span
									class="color-regular">{{data && data.throughTicketInfo.totalSaleTicket || 0}}</span>
							</el-col>
							<el-col :sm="8">
								<label>退票总数</label>
								<span
									class="color-regular">{{data && data.throughTicketInfo.refundNum || 0}}</span>
							</el-col>
							<el-col :sm="8">
								<label>退票手续费</label>
								<span
									class="color-regular">{{data && data.throughTicketInfo.refundFeeStr || 0.00}}</span>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
				<div v-if="data && data.throughTicketInfo.ticketDetails" class="sessions-box MT20">
					<el-table :data="data.throughTicketInfo.ticketDetails" style="width: 100%" size="small"
						header-row-class-name="page-list-table">
						<el-table-column prop="ticketName" label="票品"></el-table-column>
						<el-table-column label="售票总数">
							<template slot-scope="scope">
							    <p>售票数：{{scope.row.totalSaleTicket}}</p>
							    <p>总库存：{{scope.row.totalStock}}</p>
							</template>
						</el-table-column>
						<el-table-column prop="totalAmount" label="销售收入"></el-table-column>
						<el-table-column prop="sessionIncome" label="收入通票占比"></el-table-column>
						<el-table-column prop="activityIncome" label="收入演出占比"></el-table-column>						
						<el-table-column prop="sessionTicket" label="售票数通票占比"></el-table-column>
						<el-table-column prop="activityTicket" label="售票数演出占比"></el-table-column>
						<el-table-column prop="refundNum" label="退票总数"></el-table-column>
						<el-table-column prop="refundFeeStr" label="退票手续费"></el-table-column>
					</el-table>
				</div>
			</div>
			<div v-if="data && data.activityPresentTicketVOS && data.activityPresentTicketVOS.length > 0"
				class="page-group-box MT10">
				<div class="page-group-title">秀动赠票</div>
				<el-table :data="data.activityPresentTicketVOS" style="width: 100%" size="small"
					header-row-class-name="page-list-table" class="MT20">
					<el-table-column prop="ticketName" label="赠票名称"></el-table-column>
					<el-table-column prop="totalNum" label="有效赠送数量"></el-table-column>
				</el-table>
			</div>
			<div v-if="data && data.thirdTicketVOS &&  data.thirdTicketVOS.length > 0" class="page-group-box MT10">
				<div class="page-group-title">自有渠道数据</div>
				<el-table :data="data.thirdTicketVOS" style="width: 100%" size="small"
					header-row-class-name="page-list-table" class="MT20">
					<el-table-column prop="source" label="渠道"></el-table-column>
					<el-table-column prop="ticketName" label="票品"></el-table-column>
					<el-table-column prop="totalAmount" label="销售收入"></el-table-column>
					<el-table-column prop="totalNum" label="售票总数"></el-table-column>
				</el-table>
			</div>

			<div class="page-group-box MT10">
				<!-- <el-button @click="goBack">返回</el-button> -->
				<el-button v-if="id != 197710" type="primary" @click="download"
					:loading="downLoading">导出售票详单</el-button>
				<el-button type="primary" @click="$refs.batchOrderData.show()">导入自有渠道非实名制电子票</el-button>
				<!-- <el-button type="primary" @click="$refs.batchOrderData2.show()">导入自有渠道实名制电子票</el-button>
                <el-button type="primary" @click="$refs.batchStaffIcData.show()">导入工作人员</el-button> -->
			</div>
		</div>
		<BatchOrderData ref="batchOrderData" :id="id" :isSeat="data && data.isSeat"
			:sessionIdNameList="data && data.sessionIdNameList" @onUpdate="getData"></BatchOrderData>
		<BatchOrderData2 ref="batchOrderData2" :id="id" :sessionIdNameList="data && data.sessionIdNameList"
			@onUpdate="getData"></BatchOrderData2>
		<BatchStaffIcData ref="batchStaffIcData" :id="id" :sessionIdNameList="data && data.sessionIdNameList"
			@onUpdate="getData"></BatchStaffIcData>
	</div>
</template>

<script>
	// import ActivityTab from '../components/activity-tab';
	import Tab from './components/tab'
	import {
		downloadFileTmp
	} from '@/assets/js/commonApi.js';
	import BatchOrderData from './components/batchOrderData';
	import BatchOrderData2 from './components/batchOrderData2';
	import BatchStaffIcData from './components/batchStaffIcData';

	export default {
		props: {
			id: {
				type: [Number, String],
				default: null,
			},
		},
		components: {
			BatchOrderData,
			BatchOrderData2,
			BatchStaffIcData, /*ActivityTab*/
			Tab
		},
		data() {
			return {
				redirect: this.$route.query.redirect || '/manage/activity/list',
				dataLoading: false,
				data: null,
				downLoading: false,
				sessionName: ''
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			//返回
			goBack() {
				if (this.redirect) {
					this.$router.push({
						path: this.redirect,
					});
				} else {
					this.$router.go(-1);
				}
			},
			//获取数据
			getData() {
				this.dataLoading = true;
				this.$request({
						url: "/activitySaleStatistice/statistics",
						params: {
							activityId: parseInt(this.id)
						},
						method: "post",
					})
					.then((res) => {
						const {
							state,
							msg,
							result
						} = res.data;
						if (state == 1) {
							this.data = result;
						} else {
							this.$message.error(msg || "获取信息失败,请重试");
						}
					})
					.catch(() => {
						this.$message.error("获取信息失败,请重试");
					})
					.finally(() => {
						this.dataLoading = false;
					});
			},
			//导出
			async download() {
				let filePath;
				this.downLoading = true;
				const res = await this.$request({
						url: '/activitySaleStatistice/export',
						method: 'post',
						params: {
							activityId: this.id
						}
					})
					.catch(err => {
						this.$message({
							message: err.message,
							type: 'error'
						});
					})
				const {
					state,
					msg,
					result
				} = res.data;
				if (state == 1 && result) {
					filePath = result.url;
				} else {
					this.downLoading = false;
					return this.$message.error(msg || '下载失败');
				}

				await downloadFileTmp(filePath, this.id + '售票详单.' + this.$utils.getFileType(filePath));
				this.downLoading = false;
			}
		}

	}
</script>

<style lang="scss" scoped>
	::v-deep .page-group-box {
		border: 1px solid #eee;
		border-radius: 12px;		
	}
	
	.info-box {
		.el-col {
			.el-col {
				font-size: 16px;
				margin-top: 15px;

				label {
					display: inline-block;
					// width: 50%;
					margin-right: 20px;
				}
			}
		}
	}

	::v-deep .el-link.el-link--default {
		color: #145AF2
	}
	.ML10{
		margin-left: 10px;
	}
	// .sessions-box{
	//     margin-top: 30px;
	//     .card-box{
	//         margin-top: 10px;
	//         border-radius: 4px;
	//         border: 1px solid $--border-color-base;
	//         background: rgba(235, 238, 245, 0.2);
	//         padding: 5px 20px 20px 20px;
	//     }
	// }
	::v-deep .page-group-title {
		margin: 0;

		small {
			font-size: 13px;
		}
	}
	::v-deep .el-tabs__header{
		margin: 0 -21px;
	}
</style>